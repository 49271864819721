import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';
import Modal from "../../components/Modal";

import "./index.css";

import { info, error } from "../../utils/toast";
import { get, post } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const SynthesizeNft = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  // NFT等級標籤列表
  const [nftsTagList, setNftsTagList] = useState([]);
  // NFT等級标签选中
  const [activeNftsTag, setActiveNftsTag] = useState(2);
  // 默認NFT列表
  const [nftList, setNftList] = useState([]);
  const [key, setKey] = useState(0);
  // 初始化數據
  const emptyData = [
    {
      "id": null,
      "user_id": null,
      "nft_token_id": null,
      "season_id": null,
      "candidate_id": null,
      "nft_lv": null,
      "nft_type": null,
      "nft_status": null,
      "nft_from": null,
      "update_time": null,
      "show_name": "",
      "show_image": `${process.env.PUBLIC_URL}/images/plus.png`,
    },
    {
      "id": null,
      "user_id": null,
      "nft_token_id": null,
      "season_id": null,
      "candidate_id": null,
      "nft_lv": null,
      "nft_type": null,
      "nft_status": null,
      "nft_from": null,
      "update_time": null,
      "show_name": "",
      "show_image": `${process.env.PUBLIC_URL}/images/plus.png`,
    },
    {
      "id": null,
      "user_id": null,
      "nft_token_id": null,
      "season_id": null,
      "candidate_id": null,
      "nft_lv": null,
      "nft_type": null,
      "nft_status": null,
      "nft_from": null,
      "update_time": null,
      "show_name": "",
      "show_image": `${process.env.PUBLIC_URL}/images/plus.png`,
    },
    {
      "id": null,
      "user_id": null,
      "nft_token_id": null,
      "season_id": null,
      "candidate_id": null,
      "nft_lv": null,
      "nft_type": null,
      "nft_status": null,
      "nft_from": null,
      "update_time": null,
      "show_name": "",
      "show_image": `${process.env.PUBLIC_URL}/images/plus.png`,
    },
    {
      "id": null,
      "user_id": null,
      "nft_token_id": null,
      "season_id": null,
      "candidate_id": null,
      "nft_lv": null,
      "nft_type": null,
      "nft_status": null,
      "nft_from": null,
      "update_time": null,
      "show_name": "",
      "show_image": `${process.env.PUBLIC_URL}/images/plus.png`,
    }
  ];
  // 彈出層選擇NFT列表
  const [selectNftList, setSelectNftList] = useState([]);
  // 記錄彈出層選擇NFT被選中的ID
  const [checkedNftIds, setCheckedNftIds] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [synthesizeState, setSynthesizeState] = useState(false);
  // 是否自動填充數據
  const [isAuto, setIsAuto] = useState(true);
  // 是否显示全屏弹层
  const [showFullModal, setShowFullModal] = useState(false);
  // 金木水火土標籤列表
  const [attrList, setaAttrList] = useState([]);
  // 金木水火土標籤當前選中
  const [attrChecked, setAttrChecked] = useState(null);
  const carouselSettings = {
    showThumbs: false, // 禁用底部的缩略图
    infiniteLoop: false, // 循环播放
    autoPlay: false, // 自动播放
    interval: 3000, // 自动播放间隔时间（毫秒）
    showArrows: window.innerWidth < 1280 ? true : false, // 显示左右箭头
    showStatus: false, // 隐藏状态指示器
    showIndicators: false, // 禁用底部小圆点
    swipeable: false, // 允许通过滑动切换图片
    emulateTouch: false, // 启用触摸模拟
    dynamicHeight: false, // 禁用动态高度，确保图片横向展示
  };
  const nftLevs = {
    0: "Fire",
    1: "Bronze",
    2: "Silver",
    3: "Gold",
    4: "Platinum",
    5: "Diamond"
  };
  useEffect(() => {
    async function fetchData() {
      await fetchNftsTags();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchNftsTags = async () => {
    const rep = await get("yeah1Apps/getNFTConfigBySeason", {
      seasonID: 1,
    });
    if (rep.code === 0) {
      let tmpNftTagList = rep.data.seasonNFTCfgList.filter(
        (tags) => tags.nft_lv !== 1
      );
      // 設置NFT等級標籤列表數據
      setNftsTagList(tmpNftTagList);
      // 默認設置NFT列表為空列表
      setNftList(emptyData);
      // 查詢彈出選擇NFT數據標籤列表
      await fetchAttr();
      // 查詢彈出選擇NFT數據列表
      await fetchMyNFTList(tmpNftTagList, 1, null);
    } else {
      error(rep.msg);
    }
  };

  const fetchAttr = async () => {
    const attrData = [
      { id: 0, text: "Tất cả", val: null },
      { id: 1, text: "Cơ bản", val: 1 },
      { id: 2, text: "Lửa", val: 2 }
    ];
    setaAttrList(attrData);
  };

  const fetchMyNFTList = async (_tagList, _lev, _type) => {
    // console.log('fetchMyNFTList=>_tagList=>', _tagList, '_lev=>', _lev, '_type=>', _type);
    let prarm = {
      seasonID: 1,
      pageNo: 1,
      pageSize: 1000,
    };
    if (_lev) {
      prarm.nft_lv = _lev;
    }
    if (_type) {
      prarm.nft_type = _type;
    }
    const rep = await get("yeah1Apps/getMyNFTList", prarm);
    if (rep.code === 0) {
      setSelectNftList(rep.data.userNFTList);
      setSynthesizeState(false);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleNftsTag = (tagVal) => {
    setLoaderState(true);
    setActiveNftsTag(tagVal);
    setCheckedNftIds([]);
    setNftList(emptyData);
    setIsAuto(true);
    fetchMyNFTList(null, tagVal - 1, null);
  };

  const handleStartSynthesis = () => {
    fetchCraftNFT();
  };

  const fetchCraftNFT = async () => {
    const rep = await post("yeah1Apps/craftNFT", {
      nftIDs: checkedNftIds,
      nftLv: activeNftsTag - 1,
    });
    if (rep.code === 0) {
      navigate(`/nftResult?nftID=${rep.data.id}`);
    } else {
      error(rep.msg);
    }
  };

  const handleBuyMore = () => {
    navigate("/buyNft");
  };

  const handleSelectSynthesis = () => {
    setShowFullModal(true);
  };

  const handleSelectNft = (_id) => {
    // console.log('當前選擇了id=>', _id);
    if (checkedNftIds.includes(_id) === true) {
      removeIds(checkedNftIds, _id);
      forceUpdate();
    } else {
      if (checkedNftIds.length === 5) {
        info("Bạn có thể chọn tối đa 5 cái.");
      } else {
        recordIds(_id);
        forceUpdate();
      }
    }
  };

  // 記錄選擇ID
  const recordIds = async (_id) => {
    // 設置記錄選擇NFT選中NFT ID數據
    let _set = new Set(checkedNftIds);
    _set.add(_id);
    let _arrIds = Array.from(_set);
    // console.log('recordIds=>', _arrIds);
    await setCheckedNftIds(_arrIds);
  }

  // 删除选择的id
  const removeIds = async (arrayIds, _id) => {
    const index = arrayIds.indexOf(_id);
    if (index !== -1) {
      arrayIds.splice(index, 1);
    }
    // console.log('removeIds=>', arrayIds);
    await setCheckedNftIds(arrayIds);
  };

  useEffect(() => {
    // console.log('checkedNftIds updated:', checkedNftIds);
    setShowNftData();
  }, [checkedNftIds]);

  const setShowNftData = () => {
    let _showNft = emptyData;
    checkedNftIds.map((items, index) => {
      // console.log('index=>', items, 'checkedNftIds=>', checkedNftIds);
      if (isNaN(items) === false) {
        _showNft[index] = rowsNftListbyId(items);
      }
    });
    // console.log('_showNft=>', _showNft);
    setNftList(_showNft);
  };

  const rowsNftListbyId = (_id) => {
    // console.log('rowsNftListbyId=>_id=>', _id);
    const activeItems = selectNftList.filter(item => item.id === _id);
    // console.log('rowsNftListbyId=>activeItems=>', selectNftList);
    return activeItems && activeItems[0];
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // 自動選擇
  const handleAutoSelection = () => {
    setCheckedNftIds([]);
    let _ids = [];
    for (let i = 0; i < 5; i++) {
      if (i < selectNftList.length) {
        // ('i=>', i, 'id=>', selectNftList[i].id);
        _ids[i] = selectNftList[i].id;
      }
    }
    // console.log('_ids=>', _ids, 'indexs=>', _indexs);
    setCheckedNftIds(_ids);
  };

  const renderButton = () => {
    if (synthesizeState === true) {
      return (
        <>
          {isAuto === true ? (
            <div className="selection" onClick={handleAutoSelection}>
              Tự động chọn
            </div>
          ) : null}
          <div className="result-message">
            <div className="message-msg">
              Hãy sưu tầm đủ 5 quà tặng số cấp {activeNftsTag} để đổi lấy {activeNftsTag + 1} gói {nftLevs[activeNftsTag]}
            </div>
            <div className="message-go" onClick={handleBuyMore}>
              Mua thêm gói quà tặng số &gt;
            </div>
          </div>
          <div className="operation">
            {checkedNftIds.length === 5 && (
              <Button size={"small-default"} onClick={handleStartSynthesis}>
                Tổng hợp
              </Button>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          {isAuto === true ? (
            <div className="selection" onClick={handleAutoSelection}>
              Tự động chọn
            </div>
          ) : null}
          <div className="result-message">
            <div className="message-msg">
              Hãy sưu tầm đủ 5 quà tặng số cấp {activeNftsTag - 1} để đổi lấy 1 gói {nftLevs[activeNftsTag]}
            </div>
            <div className="message-go" onClick={handleBuyMore}>
              Mua thêm gói quà tặng số &gt;
            </div>
          </div>
          <div className="operation">
            {checkedNftIds.length === 5 && (
              <Button size={"small-default"} onClick={handleStartSynthesis}>
                Tổng hợp
              </Button>
            )}
          </div>
        </>
      );
    }
  };

  // 全屏彈層
  const FullModal = ({ handleClose, show, children }) => {
    useEffect(() => {
      const body = document.querySelector('.app');
      if (show === true) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "auto";
      }
      return () => {
        body.style.overflow = "auto";
      };
    }, [show]);

    return (
      <div className={`full-modal ${show ? 'show-block' : ''}`}>
        <div className="modal-main">
          {children}
        </div>
        <div className="modal-close" onClick={handleClose}></div>
      </div>
    );
  };

  const handleFullClose = () => {
    setShowFullModal(false);
  };

  // 選擇金木水火土標籤
  const handleTag = (chk) => {
    // console.log('handleTag=>chk=>', chk);
    setAttrChecked(chk);
    fetchMyNFTList(null, (activeNftsTag - 1), chk);
  };

  const forceUpdate = () => {
    setKey(prevKey => prevKey + 1);
  };

  const handleConfirm = () => {
    setShowNftData();
    handleFullClose();
  };

  const handleSlideChange = (index) => {
    // console.log('handleSlideChange=>index=>', index, 'nftlv=>', nftsTagList[index].nft_lv);
    handleNftsTag(nftsTagList[index].nft_lv);
  };

  // 每週最大投票數渲染
  const renderNftlvMaxVote = (_nft_lv) => {
    switch (_nft_lv) {
      case 2:
        return 99;
      case 3:
        return 199;
      case 4:
        return 399;
      case 5:
        return 999;
      default:
        return 99;
    }
  }

  return (
    <div className="app">
      <Seo
        title={`Yvote - Gói tổng hợp`}
        description={`Yvote - Gói tổng hợp`}
        keywords={`Yvote,Funfusion,NFT,Web3,Gói tổng hợp`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} cmd="user" />
      <div className="synthesize">
        <div className="nft-title">Chọn cấp độ tổng hợp</div>
        <div className="nft-card">
          <Carousel {...carouselSettings} onChange={handleSlideChange}>
            {nftsTagList.map((rows, index) => {
              return (
                <div
                  key={`lv_${index}`}
                  className={
                    rows.nft_lv === activeNftsTag ? "package-img package-chked" : "package-img"
                  }
                >
                  <ImageLoader
                    src={`${process.env.PUBLIC_URL}/images/lv${rows.nft_lv}.png`}
                    alt={`${nftLevs[rows.nft_lv]}`}
                  />
                  <div className="package-text">
                    {nftLevs[rows.nft_lv]}
                  </div>
                  <div className="package-help" onClick={openModal}></div>
                </div>
              );
            })}
          </Carousel>
          <div className="pc-nft-tags">
            {nftsTagList.map((rows, index) => {
              return (
                <div
                  key={`lv_${index}`}
                  className={
                    rows.nft_lv === activeNftsTag ? "package-img1 package-chked" : "package-img1"
                  }
                  onClick={() => {
                    if (window.innerWidth > 1280) {
                      handleNftsTag(rows.nft_lv);
                    }
                  }}
                >
                  <div className="package-help1" onClick={openModal}></div>
                  <ImageLoader
                    src={`${process.env.PUBLIC_URL}/images/lv${rows.nft_lv}.png`}
                    alt={`${nftLevs[rows.nft_lv]}`}
                  />
                  <div className="package-text1">
                    {nftLevs[rows.nft_lv]}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="icon-up-arrow"></div>
          <ul className="nft-select">
            {nftList.map((rows, index) => {
              return (
                <li
                  key={`nft_${index}`}
                  onClick={handleSelectSynthesis}
                >
                  <ImageLoader
                    src={rows.show_image}
                    alt={rows.show_name}
                  />
                  {rows.id === null && (
                    <div className="lev-text">{`Gói quà tặng số cấp ${(activeNftsTag - 1)}`}</div>
                  )}
                </li>
              );
            })}
            <div style={{ clear: 'both' }}></div>
          </ul>
          {renderButton()}
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
      <Modal styleCss={"default"} isOpen={modalOpen} onClose={closeModal}>
        <div className="synthesis-help">
          <div className="lix">
            <div className="icon vote-icon"></div>
            <div className="content">
              <div className="title">Tối đa {renderNftlvMaxVote(activeNftsTag)} lượt bình chọn cho mỗi tuần</div>
            </div>
          </div>
          <div className="lix">
            <div className="icon tag-icon"></div>
            <div className="content1">
              <div className="subtitle">Quà tặng số cấp {activeNftsTag}</div>
              <div className="subcontent">Gói fire loại hiếm sẽ kèm theo một quà tặng số quý hiếm.</div>
            </div>
          </div>
          <div className="lix">
            <div className="icon package-icon"></div>
            <div className="content">
              <div className="desc">Ngoài ra, nếu tổng hợp được gói fire loại hiếm sẽ có thêm phần quà bí ẩn đặc biệt!</div>
            </div>
          </div>
        </div>
      </Modal>
      <FullModal show={showFullModal} handleClose={handleFullClose}>
        <div className="select-synthesis">
          <div className="select-synthesis-toobar">
            <div className="tags-bar">
              <div className="tags-list">
                {attrList.map((tag, index) => (
                  <div
                    key={`tag_${index}`}
                    className={attrChecked === tag.val ? "chk" : "tag"}
                    onClick={() => handleTag(tag.val)}
                  >
                    {tag.text}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <center className="center">
            <ul className="select-synthesis-list" key={key}>
              {selectNftList.map((rows, index) => {
                return (
                  <li key={`nft_${index}`}
                    onClick={() => handleSelectNft(rows.id)}>
                    <img
                      className={checkedNftIds.includes(rows.id) === true ? "img-check" : ""}
                      src={rows.show_image}
                      alt={rows.show_name}
                    />
                    <div className={checkedNftIds.includes(rows.id) === true ? "nft-check" : ""}></div>
                  </li>
                );
              })}
            </ul>
            <div className="select-synthesis-num">
              {checkedNftIds.length}/5
            </div>
            <Button size={"small-default"} onClick={handleConfirm}>
              Xác nhận
            </Button>
          </center>
        </div>
      </FullModal>
    </div>
  );
};

export default SynthesizeNft;
