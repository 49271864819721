import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import { error } from "../../utils/toast";
import { formatWalletAddress, formatNumberWithCommas, isMobile } from "../../utils/tools";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import config from "../../config/global.json";

const PayConfirm = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [nft, setNft] = useState({});
  const [nftsTagList, setNftsTagList] = useState([]);
  const [user, setUser] = useState({});
  const [isAgree, setIsAgree] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [loaderState, setLoaderState] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  const nftId = searchParams.get("nftId");
  const yeah1Id = searchParams.get("yeah1Id");
  const elementType = {
    1: "Cơ bản",
    2: "Lửa"
  };

  useEffect(() => {
    async function fetchData() {
      if (nftId) {
        await fetchMyNFTDetail();
      } else {
        error(`Parameter error.`);
      }
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyNFTDetail = async () => {
    const rep = await get("yeah1Apps/getMyNFTById", {
      nftId: nftId
    });
    if (rep.code === 0) {
      setNft(rep.data.userNFTInfo);
      await fetchNftsTags(rep.data.userNFTInfo.season_id);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const fetchNftsTags = async (_seasonId) => {
    const rep = await get("yeah1Apps/getNFTConfigBySeason", {
      seasonID: _seasonId,
    });
    if (rep.code === 0) {
      setNftsTagList(rep.data.seasonNFTCfgList);
      await fetchUserNickName();
    } else {
      error(rep.msg);
    }
  };

  const fetchUserNickName = async () => {
    const rep = await get("yeah1Apps/getUserNickName", {
      id: yeah1Id,
    });
    if (rep.code === 0) {
      setUser(rep.data);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const filterNftsTags = (_nft_lv) => {
    let filteredTags = nftsTagList.filter((word) => word.nft_lv === _nft_lv);
    if (filteredTags.length >= 1) {
      let payPrice =
        (filteredTags[0].buy_price * filteredTags[0].transfer_tax_rate) / 100;
      return payPrice;
    } else {
      return "";
    }
  };

  const fetchSendNFT = async () => {
    const rep = await get("yeah1Apps/sendNFT", {
      nftID: nftId,
      toUserID: yeah1Id,
    });
    if (rep.code === 0) {
      setModalOpen(false);
      window.location.href = `${rep.data}`;
    } else {
      error(rep.msg);
    }
  };

  const handleLast = () => {
    navigate(-1);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handlePay = () => {
    setLoaderState(true);
    fetchSendNFT();
  };

  const handleOpen = () => {
    if (isMobile()) {
      window.location.href = `${config.api.agreeURL}`;
    } else {
      window.open(config.api.agreeURL);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAgree(e.target.checked);
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Gửi`}
        description={`Yvote - Funfusion Gửi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Gửi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} />
      {nft && (
        <div className="nft-details-send">
          <div className="pic">
            <ImageLoader
              src={nft.show_image}
              alt={nft.show_name}
            />
          </div>
          <div className="li">
            {nft.show_name}
          </div>
          <div className="li">Cấp độ {nft.nft_lv || 1}</div>
          <div className="li">{elementType[nft.nft_type || 1]}</div>
          {nft.nft_token_id && (
            <div className="li">
              TokenID:<span> {formatWalletAddress(nft.nft_token_id)}</span>
            </div>
          )}
          <div className="line"></div>
          <div className="li">Quà tặng số này sẽ được gửi tới:</div>
          {user && (
            <div className="user">
              <ImageLoader css="face" src={user.head_image} />
              <div className="info">
                <div className="id">{yeah1Id}</div>
                <div className="name">
                  {user.nickName && user.nickName}
                </div>
              </div>
            </div>
          )}
          <div className="sure">
            <div>
              Phí chuyển nhượng bạn phải trả:
            </div>
            <span>{formatNumberWithCommas(filterNftsTags(nft.nft_lv))}</span>
          </div>
          <div className="tips">
            Lưu ý: Vì lý do an ninh, nếu việc thanh toán phí chuyển nhượng thất bại hoặc bị hủy, món đồ được chuyển nhượng sẽ bị hệ thống khóa trong 24 giờ sau khi giao dịch được khởi xướng và sau đó sẽ được trả lại cho bạn
          </div>
          <div className="cmd">
            <Button css="no" onClick={handleLast}>
              Hủy bỏ
            </Button>
            <Button css="yes" onClick={openModal}>
              Xác nhận
            </Button>
          </div>
        </div>
      )}
      <Footer />
      <Modal styleCss={"pay"} isOpen={modalOpen} onClose={closeModal}>
        <div className="payment">
          <div className="fee">Bạn đang phải trả khoản phí này:</div>
          <div className="number">{formatNumberWithCommas(filterNftsTags(nft.nft_lv))}</div>
          <div className="readme">
            <input
              type="checkbox"
              name="agree"
              className="checkbox"
              checked={isAgree}
              onChange={handleCheckboxChange}
            />
            Tôi đã đọc và đồng ý với <span onClick={handleOpen}>Điều khoản và Điều kiện</span>
          </div>
          <div className="btns">
            <Button css={"no"} size={"small"} onClick={closeModal}>
              Hủy bỏ
            </Button>
            <Button css={isAgree === true ? "yes" : "noused"} size={"small"} onClick={handlePay}>
              Thanh toán
            </Button>
          </div>
        </div>
      </Modal>
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default PayConfirm;
