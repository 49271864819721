import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import config from "../../config/global.json";
import { useGlobalState } from "../../store/index";
import { error, info } from "../../utils/toast";
import { get } from "../../utils/http";
import { getQueryParams, queryUrl, formatNumberWithCommas, isMobile } from "../../utils/tools";
import { trackSelectItemEvent } from '../../utils/gtag';

const BuyNft = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const payResult = getQueryParams("payState");
  const payOrderID = getQueryParams("orderId");
  const inviteId = getQueryParams("inviteId");
  const { nftLev, nftNum } = useParams();
  const callbackUrl = queryUrl();
  const [modalOpen, setModalOpen] = useState(false);
  const [loaderState, setLoaderState] = useState(true);
  const [payState, setPayState] = useState("pay");
  const [quantity, setQuantity] = useState(Number(nftNum) || 1);
  const [nftsTagList, setNftsTagList] = useState([]);
  const [activeNftsTag, setActiveNftsTag] = useState(Number(nftLev) || 1);
  const [checkedNftsTag, setCheckedNftsTag] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isAgree, setIsAgree] = useState(false);
  const nftLevs = {
    0: "Fire",
    1: "Bronze",
    2: "Silver",
    3: "Gold",
    4: "Platinum",
    5: "Diamond"
  };
  const carouselSettings = {
    showThumbs: false, // 禁用底部的缩略图
    infiniteLoop: false, // 循环播放
    autoPlay: false, // 自动播放
    interval: 3000, // 自动播放间隔时间（毫秒）
    showArrows: window.innerWidth < 1280 ? true : false, // 显示左右箭头
    showStatus: false, // 隐藏状态指示器
    showIndicators: false, // 禁用底部小圆点
    swipeable: false, // 允许通过滑动切换图片
    emulateTouch: true, // 启用触摸模拟
    dynamicHeight: false, // 禁用动态高度，确保图片横向展示
  };

  useEffect(() => {
    async function fetchData() {
      await loadLocalToken();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const loadLocalToken = async () => {
    if (payResult && payOrderID) {
      if (payResult === "failed") {
        setPayState("failed");
        setModalOpen(true);
        setLoaderState(false);
      }
    }
    else if (callbackUrl) {
      window.localStorage.setItem("vote_url", callbackUrl);
      await fetchNftsTags();
    }
    else if (inviteId) {
      console.log('inviteId=>', inviteId);
      let invite = inviteId.split('?')[0];
      console.log(invite);
      await fetchNftsTags();
      await addInviteRecord(invite);
    } else {
      await fetchNftsTags();
    }
  };

  const addInviteRecord = async (_invite) => {
    await get("yeah1Apps/addInviteRecord", {
      actType: 0,
      fromId: _invite,
    });
  };

  const fetchNftsTags = async () => {
    const rep = await get("yeah1Apps/getNFTConfigBySeason", {
      seasonID: 1,
    });
    if (rep.code === 0) {
      setNftsTagList(rep.data.seasonNFTCfgList);
      filteredNftsTags(rep.data.seasonNFTCfgList, activeNftsTag);
      if (Number(nftLev || 1) > 0 && callbackUrl) {
        window.setTimeout(() => {
          setActiveNftsTag(Number(nftLev || 1));
          setSelectedIndex(Number(nftLev || 1) - 1);
          setLoaderState(false);
        }, 1000);
        if (Number(nftLev || 1) <= 3) {
          setModalOpen(true);
          setLoaderState(false);
        }
      } else {
        setLoaderState(false);
      }
    } else {
      error(rep.msg);
    }
  };

  const filteredNftsTags = (_nftsTagList, _activeNftsTags) => {
    // console.log('filteredNftsTags=>_nftsTagList=>', _nftsTagList, '_activeNftsTags=>', _activeNftsTags);
    let filteredNftsTags = null;
    if (_nftsTagList) {
      filteredNftsTags = _nftsTagList.filter(
        (tags) => tags.nft_lv == _activeNftsTags
      );
    } else {
      filteredNftsTags = nftsTagList.filter(
        (tags) => tags.nft_lv == _activeNftsTags
      );
    }
    // console.log('filteredNftsTags=>', filteredNftsTags[0]);
    setCheckedNftsTag(filteredNftsTags[0]);
  };

  const fetchBuyNFT = async () => {
    const rep = await get("yeah1Apps/buyNFT", {
      buyAmount: quantity,
      nft_lv: activeNftsTag,
    });
    if (rep.code === 0) {
      setTimeout(() => {
        window.location.href = `${rep.data}`;
        setLoaderState(false);
      }, 3000);
    } else {
      setTimeout(() => {
        setLoaderState(false);
        setPayState("failed");
      }, 3000);
    }
  };

  const handleHome = () => {
    window.location.href = config.api.voteURL;
  };

  const handleNftsTag = (tagVal, index) => {
    setActiveNftsTag(tagVal);
    setSelectedIndex(index);
    filteredNftsTags(null, tagVal);
  };

  const handleIncrease = () => {
    if (Number(Number(quantity)) >= 50) {
      info('Bạn có thể mua tối đa 50 gói quà tặng.');
      setQuantity(50);
    } else {
      setQuantity(Number(quantity) + 1);
    }
  };

  const handleDecrease = () => {
    if (Number(quantity) > 1) {
      setQuantity(Number(quantity) - 1);
    } else {
      setQuantity(Number(quantity));
    }
  };

  const handleRetry = () => {
    setLoaderState(true);
    fetchNftsTags();
    setPayState("pay");
    closeModal();
    navigate("/buyNft");
  };

  const handleBuy = () => {
    closeModal();
    setLoaderState(true);
    fetchBuyNFT();
  };

  const handleCancel = () => {
    if (callbackUrl) {
      navigate(-1);
    } else {
      closeModal();
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    if (isMobile()) {
      window.location.href = `${config.api.agreeURL}`;
    } else {
      window.open(config.api.agreeURL);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAgree(e.target.checked);
  };

  const renderPayButton = () => {
    if (payState === "failed") {
      return (
        <div className="buy-failed">
          <div className="title">Thanh toán thất bại.</div>
          <div className="desc"></div>
          <div className="cmd-button">
            <Button css={"ok okmax"} onClick={handleRetry}>
              Tải lại
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="buy-default">
          <div className="title">
            Chi tiết thanh toán
          </div>
          <div className="nft">
            <div className="x">
              <ImageLoader css={"nft-pic"} src={`${process.env.PUBLIC_URL}/images/lv${activeNftsTag}.png`} alt={`${nftLevs[activeNftsTag]} package`} />&nbsp;
            </div>
            <div className="x5">
              <strong>{checkedNftsTag && nftLevs[(checkedNftsTag.nft_lv)]}</strong>
              <ul className="details">
                <li className="sbtitle">Quyền lợi ưu đãi:</li>
                <li>Nhận ngay {checkedNftsTag && checkedNftsTag.attach_vote_count} phiếu bình chọn trên nền tảng YVote</li>
                <li>Đặc quyền bình chọn lên đến {checkedNftsTag && renderNftlvMaxVote(checkedNftsTag.nft_lv)} lượt trong tuần</li>
                <li>Quà tặng 1 hình lưu niệm phiên bản cấp {checkedNftsTag && checkedNftsTag.nft_lv}</li>
              </ul>
              <div className="nft-price">
                <div className="lf">{checkedNftsTag && formatNumberWithCommas(checkedNftsTag.buy_price)}</div>
                <div className="rt">x&nbsp;{quantity}</div>
              </div>
            </div>
          </div>
          <div className="nft-desc">
            <div className="x">
              Tổng thanh toán
            </div>
            <div className="x">
              {checkedNftsTag && formatNumberWithCommas(checkedNftsTag.buy_price * quantity)}
            </div>
          </div>
          <div className="readme">
            <input
              type="checkbox"
              name="agree"
              className="checkbox"
              checked={isAgree}
              onChange={handleCheckboxChange}
            />
            Tôi đã đọc và đồng ý với <span onClick={handleOpen}>Điều khoản và Điều kiện</span>
          </div>
          <div className="cmd-button">
            <Button css={"cancel"} onClick={handleCancel}>
              Hủy bỏ
            </Button>
            <Button css={isAgree === true ? "ok" : "noused"} onClick={() => handlePointBuy(activeNftsTag, nftLevs[activeNftsTag], checkedNftsTag.buy_price, quantity)}>
              Thanh toán
            </Button>
          </div>
        </div>
      );
    }
  };

  const handleSynthesis = () => {
    navigate("/synthesizeNft");
  };

  const handleSlideChange = (index) => {
    // console.log('handleSlideChange=>index=>', index, 'nftlv=>', nftsTagList[index].nft_lv);
    handleNftsTag(nftsTagList[index].nft_lv, index);
    setSelectedIndex(index);
  };

  // 每週最大投票數渲染
  const renderNftlvMaxVote = (_nft_lv) => {
    switch (_nft_lv) {
      case 2:
        return 99;
      case 3:
        return 199;
      case 4:
        return 399;
      case 5:
        return 999;
      default:
        return 99;
    }
  }

  const isPositiveNumber = (value) => {
    return Number.isFinite(value);
  };

  const handlePointBuy = (packageLevel, packageName, price, quantity) => {
    // console.log('packageLevel=>', packageLevel, 'packageName=>', packageName, 'price=>', price, 'quantity=>', quantity);
    try {
      const itemListName = "Vote packages";
      const items = [
        {
          item_id: packageLevel,
          item_name: packageName,
          item_brand: 'NFT',
          price: price,
          quantity: quantity,
        },
      ];
      trackSelectItemEvent(itemListName, items);
      handleBuy();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Mua gói`}
        description={`Yvote - Funfusion Mua gói`}
        keywords={`Yvote,Funfusion,NFT,Web3,Mua gói`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} onBack={handleHome} />
      <div className="buy-nft">
        <div className="toolbar">
          <div className="tags">
            <ul>
              {nftsTagList.map((rows, index) => {
                return (
                  <li
                    key={`lv_${index}_${rows.id}`}
                    className={rows.nft_lv == activeNftsTag ? "chked" : ""}
                    onClick={() => handleNftsTag(rows.nft_lv, index)}
                  >
                    <ImageLoader css={"pic"} src={`${process.env.PUBLIC_URL}/images/lv${rows.nft_lv}.png`} alt={`Cấp độ ${rows.nft_lv} package`} />
                    <div className="tags-text">
                      {nftLevs[rows.nft_lv]}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <Carousel {...carouselSettings} selectedItem={selectedIndex} onChange={handleSlideChange}>
          {nftsTagList.map((rows) => {
            return (
              <div key={`popo_${rows.id}`}>
                <div className="popo-box">
                  <div className="poster">
                    <ImageLoader src={`${process.env.PUBLIC_URL}/images/lv${rows.nft_lv}.png`} alt={`Buy Cấp độ${rows.nft_lv} package`} />
                  </div>
                  <div className="instructions">
                    <div className="rows">
                      <div className="title">
                        Quyền lợi ưu đãi:
                      </div>
                      <ul className="desc">
                        <li>Nhận ngay {rows.attach_vote_count} phiếu bình chọn trên nền tảng YVote</li>
                        <li>Đặc quyền bình chọn lên đến {renderNftlvMaxVote(rows.nft_lv)} lượt trong tuần</li>
                        <li>Quà tặng 1 hình lưu niệm phiên bản cấp {rows.nft_lv}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="vnd">
                    <div className="number">
                      {
                        rows.original_price === rows.buy_price ? (
                          <div className="buy-price">
                            {formatNumberWithCommas(rows.buy_price)}
                          </div>
                        ) : (
                          <>
                            <div className="original-price">
                              {formatNumberWithCommas(rows.original_price)}
                            </div>
                            <div className="buy-price">
                              {formatNumberWithCommas(rows.buy_price)}
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className="quantity">
                      <div className="decrease" onClick={handleDecrease}></div>
                      <input className="number" value={quantity} maxLength="3" onChange={(e) => {
                        if (isPositiveNumber(Number(e.target.value)) === false) {
                          info('Vui lòng nhập đúng định dạng số.');
                          setQuantity(1);
                          return;
                        }
                        if (Number(e.target.value) > 50) {
                          info('Bạn có thể mua tối đa 50 gói quà tặng.');
                          setQuantity(50);
                        } else {
                          setQuantity(Number(e.target.value));
                        }
                      }} />
                      <div className="increase" onClick={handleIncrease}></div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Carousel>
        <div className="button-box">
          <Button size={"small-default"} onClick={openModal}>
            Mua
          </Button>
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
      <Modal styleCss={payState} isOpen={modalOpen} onClose={closeModal}>
        {renderPayButton()}
      </Modal>
    </div>
  );
};

export default BuyNft;
