import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TopNavigationBar from "../../components/TopNavigationBar"; // 导入顶部导航栏组件
import Seo from "../../components/Seo";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import { formatWalletAddress } from "../../utils/tools";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";
import { error } from "../../utils/toast";

const MyNftDetails = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [nft, setNft] = useState({});
  const searchParams = new URLSearchParams(window.location.search);
  const nftID = searchParams.get("nftID");
  const elementType = {
    1: "Cơ bản",
    2: "Lửa"
  };

  useEffect(() => {
    async function fetchData() {
      await fetchMyNFTDetail();
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchMyNFTDetail = async () => {
    const rep = await get("yeah1Apps/getMyNFTById", {
      nftId: nftID
    });
    if (rep.code === 0) {
      setNft(rep.data.userNFTInfo);
      setLoaderState(false);
    } else {
      error(rep.msg);
    }
  };

  const handleWithdraw = (_nftId) => {
    navigate(`/nftWithdraw?nftId=${_nftId}`);
  };

  const handleSend = (_nftId) => {
    navigate(`/nftSend?nftId=${_nftId}`);
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Quà tặng số của tôi`}
        description={`Yvote - Funfusion Quà tặng số của tôi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Quà tặng số của tôi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} />
      {nft && (
        <div className="my-nft-details">
          <div className="pic">
            <ImageLoader
              src={nft.show_image}
              alt={nft.show_name}
            />
          </div>
          <div className="li">
            {nft.show_name}
          </div>
          <div className="li">Cấp độ {nft.nft_lv || 1}</div>
          <div className="li">{elementType[nft.nft_type || 1]}</div>
          {nft.nft_token_id && (
            <div className="li">
              TokenID:<span> {formatWalletAddress(nft.nft_token_id)}</span>
            </div>
          )}
          <div className="cmd">
            {/* <Button
              css={"withdraw-btn"}
              size={"small"}
              onClick={() => handleWithdraw(nft.id)}
            >
              Rút
            </Button>
            <Button css={"send-btn"}
              size={"small"}
              onClick={() => handleSend(nft.id)}>
              Gửi
            </Button> */}
            <Button
              size={"small-default"}
              onClick={() => handleWithdraw(nft.id)}>
              Gửi
            </Button>
          </div>
        </div>
      )}
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default MyNftDetails;
