import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import TopNavigationBar from "../../components/TopNavigationBar"; // 导入顶部导航栏组件
import Seo from "../../components/Seo";
import Loader from "../../components/Loader";
import Footer from "../../components/Footer";
import ImageLoader from '../../components/ImageLoader';

import "./index.css";

import { error } from "../../utils/toast";
import { get } from "../../utils/http";
import { useGlobalState } from "../../store/index";

const MyNftList = () => {
  const navigate = useNavigate();
  const [globalIsLogin] = useGlobalState(
    "globalIsLogin"
  );
  const [loaderState, setLoaderState] = useState(true);
  const [nftsTagList, setNftsTagList] = useState([]);
  const [activeNftsTag, setActiveNftsTag] = useState(1);
  const [nftList1, setNftList1] = useState([]);
  const [nftList2, setNftList2] = useState([]);
  const [nftList4, setNftList4] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await fetchNftsTags();
      await fetchMyNFT(1, 1);
      await fetchMyNFT(2, 1);
      await fetchMyNFT(4, 1);
      setLoaderState(false);
    }
    if (globalIsLogin === true) {
      fetchData();
    }
  }, [globalIsLogin]);

  const fetchNftsTags = async () => {
    const rep = await get("yeah1Apps/getNFTConfigBySeason", {
      seasonID: 1,
    });
    if (rep.code === 0) {
      setNftsTagList(rep.data.seasonNFTCfgList);
    } else {
      error(rep.msg);
    }
  };

  const fetchMyNFT = async (_type, _lev) => {
    const rep = await get("yeah1Apps/getMyNFTList", {
      seasonID: 1,
      pageNo: 1,
      pageSize: 1000,
      nft_lv: _lev,
      nft_type: _type,
    });
    if (rep.code === 0) {
      switch (_type) {
        case 2:
          setNftList2(rep.data.userNFTList);
          break;
        case 4:
          setNftList4(rep.data.userNFTList);
          break;
        default:
          setNftList1(rep.data.userNFTList);
          break;
      }
    } else {
      error(rep.msg);
    }
  };

  const handleNftsTag = async (tagVal) => {
    setActiveNftsTag(tagVal);
    setLoaderState(true);
    await fetchMyNFT(1, tagVal);
    await fetchMyNFT(2, tagVal);
    await fetchMyNFT(4, tagVal);
    setLoaderState(false);
  };

  const handleNftDetails = (_nftId) => {
    navigate(`/myNftDetails?nftID=${_nftId}`);
  };

  return (
    <div className="app">
      <Seo
        title={`Yvote - Quà tặng số của tôi`}
        description={`Yvote - Funfusion Quà tặng số của tôi`}
        keywords={`Yvote,Funfusion,NFT,Web3,Quà tặng số của tôi`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar back={true} check={true} />
      <div className="my-nft-list">
        <div className="tags-level">
          <ul className="level">
            {nftsTagList.map((rows, index) => {
              return (
                <li
                  key={`lv_${index}`}
                  className={
                    rows.nft_lv === activeNftsTag ? "li chked" : "li"
                  }
                  onClick={() => handleNftsTag(rows.nft_lv)}
                >
                  Cấp độ {rows.nft_lv}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="nfts-list">
          {nftList1 ? (
            <div className="li">
              <div className="count">Cơ bản({nftList1.length})</div>
              <div className="img-list">
                {nftList1.map((nfts, index) => {
                  return (
                    <ImageLoader
                      key={`img1_${index}`}
                      src={nfts.show_image}
                      alt={nfts.show_name}
                      onClick={() => handleNftDetails(nfts.id)}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {nftList2 ? (
            <div className="li">
              <div className="count">Lửa({nftList2.length})</div>
              <div className="img-list">
                {nftList2.map((nfts, index) => {
                  return (
                    <ImageLoader
                      key={`img2_${index}`}
                      src={nfts.show_image}
                      alt={nfts.show_name}
                      onClick={() => handleNftDetails(nfts.id)}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
          {nftList4 ? (
            <div className="li">
              <div className="count">Birthday({nftList4.length})</div>
              <div className="img-list">
                {nftList4.map((nfts, index) => {
                  return (
                    <ImageLoader
                      key={`img4_${index}`}
                      src={nfts.show_image}
                      alt={nfts.show_name}
                      onClick={() => handleNftDetails(nfts.id)}
                    />
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      <Loader isOpen={loaderState} />
    </div>
  );
};

export default MyNftList;
