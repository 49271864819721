import React, { useState } from "react";

import TopNavigationBar from "../../components/TopNavigationBar";
import Seo from "../../components/Seo";
import Footer from "../../components/Footer";

import "./index.css";

import config from "../../config/global.json";

const FAQItem = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq">
      <div className="faq-label" onClick={() => setIsOpen(!isOpen)}>
        {children[0]}
        <span className={`arrow ${isOpen ? 'open' : ''}`}></span>
      </div>
      {isOpen && <div className="faq-content">{children[1]}</div>}
    </div>
  );
}

const Faq = () => {
  return (
    <div className="app">
      <Seo
        title={`Yvote - Hướng dẫn`}
        description={`Yvote - Funfusion Hướng dẫn`}
        keywords={`Yvote,Funfusion,NFT,Web3,Hướng dẫn,FAQ`}
        image={`${window.location.host}/${process.env.PUBLIC_URL}/images/funfusion.png`}
        url={window.location.href}
      />
      <TopNavigationBar check={false} />
      <div className="faq-container">
        <h1>HƯỚNG DẪN</h1>
        <FAQItem>
          <div>Các gói bình chọn 1PRODUCTION đang cung cấp gồm những loại nào?</div>
          <div>Các gói được phận biệt theo cấp độ và độ hiếm, với các cấp độ từ 1 đến 5(Bronze, Sliver, Gold, Platinum, Diamond). Mỗi cấp độ bao gồm 2 thuộc tính: Thông thường và Lửa, trong đó thuộc tính Lửa là hiếm, có giá trị cao và đặc biệt thuộc vật phẩm sưu tầm hiếm.</div>
        </FAQItem>
        <FAQItem>
          <div>Thông tin chi tiết của từng gói?</div>
          <div>
            Thông tin chi tiết các gói
            <ul>
              <li>Bronze: 1 quà tặng số cấp 1 là vật phẩm ngẫu nhiên của một Anh Trai, 4 phiếu bầu và tối đa 99 số phiếu bầu hàng tuần.</li>
              <li>Silver: 1 quà tặng số cấp 2 là vật phẩm ngẫu nhiên của một Anh Trai, 20 phiếu bầu và tối đa 99 số phiếu bầu hàng tuần.</li>
              <li>Gold: 1 quà tặng số cấp 3 là vật phẩm ngẫu nhiên của một Anh Trai, 80 phiếu bầu và tối đa 199 số phiếu bầu hàng tuần.</li>
              <li>Platinum: 1 quà tặng số cấp 4 là vật phẩm ngẫu nhiên của một Anh Trai, 120 phiếu bầu và tối đa 399 số phiếu bầu hàng tuần.</li>
              <li>Diamond: 1 quà tặng số cấp 5 là vật phẩm ngẫu nhiên của một Anh Trai, 360 phiếu bầu và tối đa 999 số phiếu bầu hàng tuần.</li>
            </ul>
            Lưu ý: Các gói quy đổi/ cải tiến không bao gồm phiếu bầu.
          </div>
        </FAQItem>
        <FAQItem>
          <div>Làm thế nào để thêm các phiếu bầu vào tài khoản của tôi?</div>
          <div>Sau khi thanh toán thành công, hệ thống sẽ tự động thêm số phiếu bình chọn vào tài khoản của bạn. Ngay sau đó có thể bình chọn ngay Anh Trai bạn yêu thích nhất.</div>
        </FAQItem>
        <FAQItem>
          <div>Làm thế nào để nâng cấp lượt bình chọn hằng tuần?</div>
          <div>Nếu tài khoản của bạn đang ở cấp độ 2( Silver) và nâng cấp lên cấp 3 (Gold), tài khoản của bạn sẽ được tăng giới hạn số lượt bình chọn lên đến 199 lượt/ tuần. Nếu mua thêm cấp 3 (Gold) vẫn sẽ không tăng số lượng giới hạn bình chọn mỗi tuần. Thay vào đó, hãy nâng cấp lên gói 4,5 (Platinum, Diamond).</div>
        </FAQItem>
        <FAQItem>
          <div>Quà tặng số là gì? Tại sao phải mua quà tặng số khi bình chọn?</div>
          <div>Quà tặng số là một loại tài sản kỹ thuật số độc nhất, được sử dụng để bình chọn trên nền tảng YVote. Mỗi quà tặng số đại diện cho một nhân vật trong chương trình, nếu sở hữu chúng sẽ giúp bạn thể hiện sự ủng hộ của mình một cách đặc biệt đến nhân vật được đề cử.</div>
        </FAQItem>
        <FAQItem>
          <div>Tại sao quà tặng số có giá trị?</div>
          <div>
            <ul>
              <li><strong>Tính độc đáo và khan hiếm:</strong> Mỗi quà tặng số là duy nhất. Tính chất giới hạn làm cho chúng khan hiếm, do đó có giá trị cao.</li>
              <li><strong>Quyền sở hữu:</strong> Khi bạn mua một quà tặng số, bạn có quyền sở hữu mã thông báo duy nhất. Mặc dù tài sản số có thể được sao chép, nhưng quyền sở hữu được ghi lại trên blockchain không thể sao chép được.</li>
              <li><strong>Khả năng sưu tầm và giao dịch:</strong> Giống như sưu tầm các vật phẩm hiếm hoặc nghệ thuật, quà tặng số có thể được sưu tầm, trưng bày hoặc thậm chí bán. Giá trị của chúng có thể tăng theo thời gian, đặc biệt nếu chúng có nhu cầu cao hoặc trở nên có ý nghĩa văn hóa.</li>
              <li><strong>Kết nối với ngôi sao:</strong> Sở hữu một quà tặng số từ chương trình của chúng tôi cho phép bạn có một món kỷ niệm số trực tiếp liên kết với ngôi sao yêu thích của bạn, tăng giá trị tình cảm và tiềm năng giá trị trong tương lai của nó.</li>
            </ul>
          </div>
        </FAQItem>
        <FAQItem>
          <div>Làm thế nào để mua gói bình chọn?</div>
          <div>Bạn có thể nhấp vào gói mong muốn từ cổng mua sự kiện hoặc truy cập trực tiếp vào trang mua gói tại <a href={`${config.api.siteURL}/buyNft`}>{`${config.api.siteURL}/buyNft`}</a></div>
        </FAQItem>
        <FAQItem>
          <div>Gói Lửa có gì đặc biệt so với các gói còn lại?</div>
          <div>Gói Lửa là gói phiên bản giới hạn, chứa một quà tặng số hiếm, giá trị cao và có thể mang lại lợi ích khi giao dịch trên thị trường. Giá trị hơn nữa là gói Lửa có thể mang theo lại quyền lợi đặc biệt, cũng như số lượt bình chọn tối đa trong tuần.</div>
        </FAQItem>
        <FAQItem>
          <div>Làm thế nào để sưu tầm gói Lửa?</div>
          <div>
            <ul>
              <li>Gói Lửa không thể mua trực tiếp, có thể thông qua tổng hợp. Bạn cần thu thập 5 quà tặng số cấp thấp hơn và sau đó tổng hợp chúng thành một gói cấp cao hơn (ví dụ: 5 quà tặng số Bronze thành một gói Silver).</li>
              <li>Nếu bạn có nhiều quà tặng số cấp thấp, hãy can đảm thử tổng hợp và chúc bạn may mẳn!</li>
              <li>Lưu ý: Quà tặng số được sử dụng cho tổng hợp sẽ bị hủy khi tổng hợp thành công. Trang tổng hợp:<a href={`${config.api.siteURL}/synthesizeNft`}>{`${config.api.siteURL}/synthesizeNft`}</a></li>
            </ul>
          </div>
        </FAQItem>
        <FAQItem>
          <div>Làm thế nào để sử dụng tính năng tổng hợp?</div>
          <div>
            <ul>
              <li>Bước 1: Nhấp chuột vào "GÓI TỔNG HỢP" trên thanh menu</li>
              <li>Bước 2: Chọn gói bạn muốn nâng cấp lên và thêm các quà tặng số bạn muốn tổng hợp (cần phải tổng hợp các quà tặng số cùng cấp độ. Ví dụ tổng hợp 5 quà tặng số cấp 1 để đổi lấy 1 quà tặng số cấp 2)</li>
              <li>
                Bước 3: Sau khi tổng hợp thành công bạn sẽ được nhận 1 gói nâng cấp mới không bao gồm số lượt bình chọn. Nếu may mắn, bạn có thể được nhận một gói Lửa hiếm.
              </li>
            </ul>
          </div>
        </FAQItem>
        <FAQItem>
          <div>Có thể rút quà tặng số về ví của tôi không?</div>
          <div>
            Có, bạn có thể gửi quà tặng số đến ví của tôi
            <ul>
              <li>Bước 1: Trên trang QUÀ TẶNG SỐ CỦA TÔI (<a href={`${config.api.siteURL}/myNftList`}>{`${config.api.siteURL}/myNftList`}</a>), tìm quà tặng số bạn muốn gửi về ví, mở chi tiết và nhấp gửi. Trong giao diện xuất hiện, nhập FunlD của bạn.</li>
              <li>Bước 3: Nhấp Gửi hệ thống sẽ gửi quà tặng số đến địa chỉ ví của tôi. Lưu ý bạn sẽ chịu phí giao dịch khi rút về ví của tôi.</li>
            </ul>
          </div>
        </FAQItem>
        <FAQItem>
          <div>Làm thế nào để xem lịch sử giao dịch quà tặng số của tôi?</div>
          <div>
            Bạn có thể xem lịch sử rút tiền, gửi và nhận quà tặng số thông qua trang Lịch sử Chuyển nhượng (<a href={`${config.api.siteURL}/history`}>{`${config.api.siteURL}/history`}</a>)
          </div>
        </FAQItem>
      </div >
      <Footer />
    </div >
  );
}

export default Faq;